import React from 'react';
import logo_black from '../images/logo-black.png';
import { Link } from 'gatsby';

const Navigation = props => {

    const renderSmallButton = () => {
        const { showButton } = props;

        if (!showButton) {
            return null;
        }

        return (
            <Link to="/booking" className="mr-4 font-bold rounded-full md:mt-0 py-2 px-4 md:hidden shadow opacity-75 gradient text-white" href="#">
                Book
            </Link>
        );
    }

    return (
        <nav id="header" className="fixed w-full z-30 top-0 text-white bg-white shadow header">
            <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
                <Link to="/" className="pl-4 flex items-center">
                    <div className="toggleColour py-2 no-underline hover:no-underline logoContainer">
                        <img className="pr-2 max-h-10 md:max-h-14" src={logo_black} alt="logo" />
                        <div className="flex flex-col justify-center">
                            <div className="text-2xl md:text-3xl leading-none">MEXÍA</div>
                            <div className="text-xs tracking-tight uppercase">Massage & Bodywork</div>
                        </div>
                    </div>
                </Link>

                {renderSmallButton()}

                <div className="w-full flex-grow md:flex md:items-center md:w-auto hidden md:block mt-2 md:mt-0 bg-white md:bg-transparent text-black p-4 md:p-0 z-20" id="nav-content">
                    <ul className="list-reset md:flex justify-end flex-1 items-center">
                        <li className="navItem">
                            <Link to="/treatments" className="inline-block py-2 pr-6 text-black no-underline" href="#">
                                Bodywork & Treatments
                            </Link>
                        </li>
                        <li className="navItem">
                            <Link to="/giftcard" className="inline-block py-2 pr-6 text-black no-underline" href="#">
                                Gift Cards
                            </Link>
                        </li>
                    </ul>
                    <Link to="/booking" className="mx-auto md:mx-0 font-bold rounded-full mt-4 md:mt-0 py-2 px-8 shadow opacity-75 gradient text-white" href="#">
                        Book Now
                    </Link>
                </div>
            </div>
        </nav>
    )
}

Navigation.defaultProps = {
    showButton: true
}

export default Navigation;
