import React from 'react';
import Waves from '../components/waves';
import Navigation from '../components/navigation';
import Cta from '../components/cta';
import Footer from '../components/footer';

import deepTissue from '../images/deeptissue.jpeg';
import backPain from '../images/back_pain_woman.jpg';
import sportsMassage from '../images/sports-massage.jpeg';
import therapeutic from '../images/therapeutic.jpeg';
import cupping from '../images/cupping.jpeg';
import scraping from '../images/scraping.jpeg';
import stretching from '../images/assisted-stretching.jpeg';

import '../css/typography.css';
import cross from "../images/cross.svg";
import check from "../images/check.svg";

const Treatments = () => {
    const listItem = (icon, text) => {
        return (
            <div className="flex flex-no-wrap items-center">
                <img className="max-h-8 md:max-h-11 pr-2 md:pr-4" src={icon} alt="cross" />
                {text}
            </div>
        )
    }

    return (
        <body className="leading-normal tracking-normal text-white gradient">
            <Navigation />
            <div className="pt-4">
                <Waves />
            </div>

            <section className="py-6 bg-white text-gray-800">
                <div className="container max-w-4xl mx-auto m-8">

                    <div className="flex flex-wrap justify-center py-10 md:py-20">
                        <div className="md:w-1/3 px-8">
                            <h3 className="text-3xl text-gray-800 font-bold mb-3">Say Good-Bye To “One Size Fits All” Therapy</h3>
                        </div>
                        <div className="md:w-2/3 px-8">
                            <p>Mexía Bodywork offers the most advanced bodyworking techniques to rejuvenate your health from head to toe. We craft personalized plans with layers of therapeutic relief to cover every last one of your pain points. Your blended therapy treatment plan could include one or all of the following options below.</p>
                        </div>
                    </div>

                </div>
            </section>

            <section className="bg-gray-100 py-6 bg-white text-gray-800">
                <div className="container max-w-5xl mx-auto m-8">

                    <h3 className="w-full my-2 text-3xl font-bold leading-tight text-center text-gray-800">Treatments</h3>
                    <div className="w-full mb-4">
                        <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
                    </div>

                    <div className="flex flex-wrap justify-center py-10 md:py-20">
                        <div className="w-full md:w-1/2 px-8">
                            <h3 className="text-2xl md:text-3xl text-gray-800 font-bold mb-3">Deep Tissue Massage</h3>
                            <p className="text-gray-600 mb-12">Improve range of motion and relieve mental and physical stress with slow, deep strokes that targets pain beneath the skin’s surface (muscles and connective tissue), giving you lasting and immediate relief.</p>
                        </div>
                        <div className="w-full md:w-1/2 px-8">
                            <img className="imageFilter rounded-lg" src={deepTissue} />
                        </div>
                    </div>

                    <div className="flex flex-wrap justify-center py-10 md:py-20">
                        <div className="w-full md:w-1/2 px-8">
                            <h3 className="text-2xl md:text-3xl text-gray-800 font-bold mb-3">Sports Massage</h3>
                            <p className="text-gray-600 mb-12">Sustain top athletic performance, prevent future injuries, and heal your body from past trauma with a customized massage designed to keep your body operating at its fullest potential. For professional or recreational athletes with physically active lifestyles.</p>
                        </div>
                        <div className="w-full md:w-1/2 px-8">
                            <img className="imageFilter rounded-lg" src={sportsMassage} />
                        </div>
                    </div>

                    <div className="flex flex-wrap justify-center py-10 md:py-20">
                        <div className="w-full md:w-1/2 px-8">
                            <h3 className="text-2xl md:text-3xl text-gray-800 font-bold mb-3">Therapeutic Massage</h3>
                            <p className="text-gray-600 mb-12">A customized massage for ultimate physical relaxation using a blended mixture of firm pressure, trigger point therapy, myofascial release, and fascia stretch therapy, reestablishing harmony in both body and mind.</p>
                        </div>
                        <div className="w-full md:w-1/2 px-8">
                            <img className="imageFilter rounded-lg" src={therapeutic} />
                        </div>
                    </div>


                    <div className="flex flex-wrap justify-center py-10 md:py-20">
                        <div className="w-full md:w-1/2 px-8">
                            <h3 className="text-2xl md:text-3xl text-gray-800 font-bold mb-3">Cupping Therapy</h3>
                            <p className="text-gray-600 mb-12">This therapy utilizes a special tool that creates suction within plastic or silicone cups applied to the body. The negative pressure within the cups stimulates blood flow to targeted areas experiencing joint or muscle pain and reduce inflammation, accelerate recovery, and improve function of the lymphatic and circulatory system.<br /><br />
                                Cupping is safe for almost everyone and can be performed almost anywhere in the body. The sensation of the cups can be customized based on the strength of the suction and can be static or moved around in gliding motions to feel like a massage.<br /><br />
                            </p>
                        </div>
                        <div className="w-full md:w-1/2 px-8">
                            <img className="imageFilter rounded-lg" src={cupping} />
                        </div>
                    </div>
                    <div className="flex flex-wrap justify-center py-10 md:py-20">
                        <div className="w-full md:w-1/2 px-8">
                            <h3 className="text-2xl md:text-3xl text-gray-800 font-bold mb-3">Muscle Scraping</h3>
                            <p className="text-gray-600 mb-12">Dig deep into past pain and heal soft tissue damage at the root – quickly and effectively. Dario will apply specialized metal instruments and advanced body working techniques to target pain and release muscle tension, reversing the effects of previous injuries on your body. These instruments effectively break down fascial restrictions and scar tissues with the appropriate amount of pressure.</p>
                        </div>
                        <div className="w-full md:w-1/2 px-8">
                            <img className="imageFilter rounded-lg" src={scraping} />
                        </div>
                    </div>
                    <div className="flex flex-wrap justify-center py-10 md:py-20">
                        <div className="w-full md:w-1/2 px-8">
                            <h3 className="text-2xl md:text-3xl text-gray-800 font-bold mb-3">Fascial Stretch Therapy™</h3>
                            <p className="text-gray-600 mb-12">Fascial Stretch Therapy™ (FST) is a unique approach to assisted stretching that, focuses on the connective tissue, “fascia”, rather than just isolated individual muscles. Fascia is our connective tissue system that penetrates through and wraps around everything in our body: muscles, organs, and nerves. The techniques used in FST stretches your “nets” of fascia along with your muscles.<br /><br />
                                FST Sessions are personalized and pain-free to improve quality of life, promote faster recovery from fitness & athletic activities, and reduces pain caused by lack of mobility & flexibility.</p>
                        </div>
                        <div className="w-full md:w-1/2 px-8">
                            <img className="imageFilter rounded-lg" src={stretching} />
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-6 bg-white text-gray-800">
                <div className="container max-w-5xl mx-auto">

                    <div className="flex flex-wrap justify-center items-center py-10 md:py-20">
                        <div className="hidden sm:block md:w-1/3 px-8">
                            <img className="imageFilter mx-auto max-h-96" alt="back pain" src={backPain} />
                        </div>
                        <div className="md:w-2/3 px-8 pt-10 sm:pt-0">
                            <h3 className="text-2xl sm:text-3xl text-gray-800 font-bold mb-8">You wouldn’t drive around in a car that desperately needed maintenance, so why drive your own body without it?</h3>
                            <p>Your body is the most valuable machine you’ll ever invest in. It’s responsible for keeping you going each and everyday for the rest of your life. But the vast majority of people (maybe even you!) go through life feeling like your body’s check engine light is on, but you just keep grinding away as if nothing is wrong.</p><br />
                            <p>Maybe you’ve got mysterious aches and pains, an overwhelming sense of exhaustion or past injuries that NEVER seem to heal... And it’s clear you need something deeper than an ordinary trip to the massage therapist to see the results and the relief you want and deserve.</p>
                        </div>
                    </div>

                </div>
            </section>

            <section className="bg-gray-100 border-t border-b text-gray-800">
                <div className="container max-w-5xl mx-auto">

                    <div className="flex flex-wrap justify-center py-10 md:py-20">
                        <div className="md:w-2/3 px-8">
                            <h3 className="my-2 text-2xl sm:text-3xl font-bold leading-tight text-center text-gray-800">Why regular massages don't work</h3>
                            <h2 className="my-2 text-lg sm:text-2xl font-bold leading-tight text-center text-gray-800 pb-10">(and why bodywork is the answer)</h2>
                            <h3 className="my-2 text-lg sm:text-xl text-center text-gray-800">Traditional Massage</h3>
                            <div className="w-full mb-8">
                                <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
                            </div>
                            <ul className="crosses">
                                <li>{listItem(cross, "Limited to focusing on one style of treatment")}</li>
                                <li>{listItem(cross, "Addresses only physical symptoms, but not the underlying causes")}</li>
                                <li>{listItem(cross, "Provides only temporary relief from on-going pain and stress")}</li>
                                <li>{listItem(cross, "Leaves you with no applicable skills for daily self-care")}</li>
                            </ul>
                            <h3 className="my-2 text-lg sm:text-xl text-center text-gray-800">Bodywork</h3>
                            <div className="w-half mb-8">
                                <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
                            </div>
                            <ul className="checks">
                                <li>{listItem(check, "Targeted relief that incorporates many disciplines in a harmonious, customized way")}</li>
                                <li>{listItem(check, "Digs deep into the root of your pain and leaves no stones unturned")}</li>
                                <li>{listItem(check, "Provides long-term relief, so you can enjoy your life more each day")}</li>
                                <li>{listItem(check, "Teaches you valuable techniques that you can apply to your daily routine for a lifetime of healing")}</li>
                            </ul>
                        </div>
                    </div>

                </div>

            </section>

            <Cta />
            <Footer />
        </body>
    )
}

export default Treatments;

