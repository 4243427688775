import React from 'react';
import { Link } from 'gatsby';

import logo from '../images/logo.png';

const Footer = () => {
    return (
        <footer className="bg-white">
            <div className="container mx-auto  px-8">

                <div className="w-full flex flex-col md:flex-row py-6">

                    <div className="flex-1 mb-6 mr-6">
                        <img className="logo" src={logo} alt="Mexía" />
                    </div>

                    <div className="flex-1 mb-6">
                        <p className="uppercase text-gray-500 mb-2 md:mb-6">Licences</p>
                        <p className="text-gray-800 mb-2"><b>DC</b>:  MT2583</p>
                        <p className="text-gray-800 mb-2"><b>VA</b>:  0019018145</p>
                        <p className="text-gray-800"><b>MD</b>:  R03353</p>
                    </div>
                    <div className="flex-1">
                        <p className="uppercase text-gray-500 md:mb-6">Links</p>
                        <ul className="list-reset mb-6">
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link to="/treatments"  className="no-underline hover:underline text-gray-800 hover:text-orange-500">Treatments</Link>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link to="/booking" className="no-underline hover:underline text-gray-800 hover:text-orange-500">Book Appointment</Link>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link to="/policy"  className="no-underline hover:underline text-gray-800 hover:text-orange-500">Cancellation Policy</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="flex-1">
                        <p className="uppercase text-gray-500 md:mb-6">Contact</p>
                        <ul className="list-reset mb-6">
                            <li className="mt-2 inline-block mr-2 text-gray-800 md:block md:mr-0">
                                <b>Email</b>: <a href="mailto:info@mexiabodywork.com" className="underline">info@mexiabodywork.com</a>
                            </li>
                            <li className="mt-2 text-gray-800 mr-2 md:block md:mr-0">
                                <b>Locations</b>:
                            </li>
                            <li className="mt-2 inline-block text-gray-800 mr-2 md:block md:mr-0">
                                <a href="https://goo.gl/maps/fuGr6tckKwoXWw1E8" className="underline" target="_blank">1638 R St. NW Suite 215, Washington, DC</a>
                            </li>
                            <li className="mt-2 inline-block text-gray-800 mr-2 md:block md:mr-0">
                                <a href="https://maps.app.goo.gl/AAEVCXpx16Fcqed27" className="underline" target="_blank">9017 Red Branch Rd. Suite A, Columbia, MD</a>
                            </li>
                            <li className="mt-2 inline-block text-gray-800 mr-2 md:block md:mr-0">
                                (12 Labours Crossfit Gym)
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
